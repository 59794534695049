// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admissions-process-js": () => import("./../../../src/pages/admissions-process.js" /* webpackChunkName: "component---src-pages-admissions-process-js" */),
  "component---src-pages-all-american-graduate-program-js": () => import("./../../../src/pages/all-american-graduate-program.js" /* webpackChunkName: "component---src-pages-all-american-graduate-program-js" */),
  "component---src-pages-all-american-undergraduate-program-js": () => import("./../../../src/pages/all-american-undergraduate-program.js" /* webpackChunkName: "component---src-pages-all-american-undergraduate-program-js" */),
  "component---src-pages-alumni-connect-js": () => import("./../../../src/pages/alumni-connect.js" /* webpackChunkName: "component---src-pages-alumni-connect-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-countries-study-in-australia-js": () => import("./../../../src/pages/countries/study-in-australia.js" /* webpackChunkName: "component---src-pages-countries-study-in-australia-js" */),
  "component---src-pages-countries-study-in-singapore-js": () => import("./../../../src/pages/countries/study-in-singapore.js" /* webpackChunkName: "component---src-pages-countries-study-in-singapore-js" */),
  "component---src-pages-countries-study-in-united-kingdom-js": () => import("./../../../src/pages/countries/study-in-united-kingdom.js" /* webpackChunkName: "component---src-pages-countries-study-in-united-kingdom-js" */),
  "component---src-pages-countries-study-in-usa-js": () => import("./../../../src/pages/countries/study-in-usa.js" /* webpackChunkName: "component---src-pages-countries-study-in-usa-js" */),
  "component---src-pages-event-listing-js": () => import("./../../../src/pages/event-listing.js" /* webpackChunkName: "component---src-pages-event-listing-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-global-online-accelerator-program-js": () => import("./../../../src/pages/global-online-accelerator-program.js" /* webpackChunkName: "component---src-pages-global-online-accelerator-program-js" */),
  "component---src-pages-global-year-1-program-js": () => import("./../../../src/pages/global-year-1-program.js" /* webpackChunkName: "component---src-pages-global-year-1-program-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partnership-js": () => import("./../../../src/pages/partnership.js" /* webpackChunkName: "component---src-pages-partnership-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */),
  "component---src-pages-student-financing-js": () => import("./../../../src/pages/student-financing.js" /* webpackChunkName: "component---src-pages-student-financing-js" */),
  "component---src-pages-student-success-js": () => import("./../../../src/pages/student-success.js" /* webpackChunkName: "component---src-pages-student-success-js" */),
  "component---src-pages-student-visa-js": () => import("./../../../src/pages/student-visa.js" /* webpackChunkName: "component---src-pages-student-visa-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-uniauckland-js": () => import("./../../../src/pages/uniauckland.js" /* webpackChunkName: "component---src-pages-uniauckland-js" */),
  "component---src-templates-event-detail-js": () => import("./../../../src/templates/event-detail.js" /* webpackChunkName: "component---src-templates-event-detail-js" */),
  "component---src-templates-landing-pages-js": () => import("./../../../src/templates/landing-pages.js" /* webpackChunkName: "component---src-templates-landing-pages-js" */),
  "component---src-templates-program-detail-js": () => import("./../../../src/templates/program-detail.js" /* webpackChunkName: "component---src-templates-program-detail-js" */),
  "component---src-templates-program-listing-archive-js": () => import("./../../../src/templates/program-listing-archive.js" /* webpackChunkName: "component---src-templates-program-listing-archive-js" */),
  "component---src-templates-study-resources-archive-js": () => import("./../../../src/templates/study-resources-archive.js" /* webpackChunkName: "component---src-templates-study-resources-archive-js" */),
  "component---src-templates-study-resources-category-js": () => import("./../../../src/templates/study-resources-category.js" /* webpackChunkName: "component---src-templates-study-resources-category-js" */),
  "component---src-templates-study-resources-js": () => import("./../../../src/templates/study-resources.js" /* webpackChunkName: "component---src-templates-study-resources-js" */),
  "component---src-templates-university-detail-js": () => import("./../../../src/templates/university-detail.js" /* webpackChunkName: "component---src-templates-university-detail-js" */)
}

